<template>
  <div>
    <div class="ma-2">
      <h1 class="primary--text">Derniers commentaires</h1>
    </div>
    <v-row v-if="loading" align-content="center" justify="center">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-row>
    <v-row v-else>
      <v-col cols="12" md="10" xl="8">
        <v-data-iterator :items="commentaires" item-key="id" :items-per-page="5">
          <template v-slot:item="{item}">
            <v-card :to="item.ticket !== null ? '/tickets/' + item.ticket : item.contract !== null ? '/contracts/' + item.contract : null" class="my-1 mx-2">
              <v-card-title class="primary--text">
                {{ item.created_by.first_name + " " + item.created_by.last_name }}
              </v-card-title>
              <v-card-subtitle v-if="item.ticket !== null">
                <span class="mb-2">{{ formatDate(item.date_creation) }}</span><br>
                <router-link class="text-decoration-none" :to="'/tickets/' + item.ticket">
                  <v-icon color="primary">mdi-ticket</v-icon>
                  Ticket #{{ item.ticket }}</router-link>
              </v-card-subtitle>
              <v-card-subtitle v-else-if="item.contract !== null">
                <span class="mb-2">{{ formatDate(item.date_creation) }}</span><br>
                <router-link class="text-decoration-none" :to="'/contracts/' + item.contract">
                  <v-icon color="primary">mdi-file-document-multiple</v-icon>
                  Contrat #{{ item.contract }}</router-link>
              </v-card-subtitle>
              <v-card-text>{{ item.message }}</v-card-text>
            </v-card>
          </template>
        </v-data-iterator>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DateUtilMixin from "@/mixins/util/DateUtilMixin";

export default {
  name: "Discussion",
  mixins: [DateUtilMixin],
  async mounted() {
    this.$store.commit("commentaires/setCommentaires", []);
    this.loading = true;
    await this.$store.dispatch("commentaires/fetchCommentaires");
    this.loading = false;
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    commentaires() {
      return this.$store.getters["commentaires/commentaires"];
    }
  }

}
</script>

<style scoped>

</style>